import React from 'react';

import Layout from '../components/Layout';
import { Seo } from '../components/Seo';

import yamaha from '../assets/images/yamaha.png';
import malaguti from '../assets/images/malaguti.png';
import suzuki from '../assets/images/suzuki.png';
import piaggio from '../assets/images/piaggio.png';
import keeway from '../assets/images/keeway.png';
import kymco from '../assets/images/kymco.jpg';
import mbk from '../assets/images/mbk.png';
import vespa from '../assets/images/vespa.png';
import cpi from '../assets/images/cpi.png';
import peugeot from '../assets/images/peugeot.jpg';
import aprilia from '../assets/images/aprilia.jpg';
import gilera from '../assets/images/gilera.png';
import derbi from '../assets/images/derbi.png';
import honda from '../assets/images/honda.jpg';
import italjet from '../assets/images/italjet.png';
import pic1 from '../assets/images/000166_003_000381.jpg';
import pic2 from '../assets/images/000164_003_000371.jpg';
import pic3 from '../assets/images/000162_004_000361.jpg';

const IndexPage = () => (
  <Layout>
    <Seo
      title='Skútr servis Praha'
      keywords='scooter, skutr, servis Praha'
      description='skutr servis, scooter servis, opravy skutru v praze'
    />
    <div className='frame'>
      <div className='frame_col mr20'>
        <br /><br />
        <img src={yamaha} alt='yamaha skútr' />
        <br /><br />
        <img src={malaguti} alt='malaguti skútr' />
        <br /><br />
        <img src={suzuki} alt='suzuki skútr' />
        <br /><br />
        <img src={piaggio} alt='piaggio skútr' />
        <br /><br />
        <img src={keeway}
             alt='keeway skútr' />
        <br /><br />
        <img src={kymco}
             alt='kymco skútr' />
        <br /><br />
        <img src={mbk} alt='mbk skútr' />
      </div>
      <div id='bottom'>
        <div id='content'>
          <div id='left_text'><br /><br />
            <h2>Scooter #83 servis</h2>
            <div className='news'>
              <div className='item'>
                <div className='label'><span className='fw'>Sportovní variátor Naraku pro GY6</span> - <span
                  className='fs9'>22. 12. 2018</span></div>
                <p><img className='__mce_tmp_pic'
                        src={pic1}
                        alt='bez názvu' /></p>
              </div>
              <div className='item'>
                <div className='label'><span className='fw'>Kit válce 160 cmm pro čínské skůtry</span> - <span
                  className='fs9'>18. 06. 2014</span></div>
                <p><img className='__mce_tmp_pic'
                        src={pic2}
                        alt='bez názvu' /></p>
              </div>
            </div>
          </div>
          <div id='right_text'><br /><br /><br /><br />
            <h2>Nabízíme Vám</h2>
            <ul>
              <li>Opravy skůtrú dvoutaktních a čtyřtaktních</li>
              <li>Tuningové úpravy - zvýšení výkonu</li>
              <li>Prodej a výměna pneu různých značek</li>
              <li>Příprava na STK</li>
              <li>Od roku 2015 servis skůtrú čínské výroby</li>
              <li>Parkování v zimním období</li>
              <li>Poradenství při koupi ojetého skůtru</li>
            </ul>
            <p><img className='__mce_tmp_pic'
                    style={{width: '100%'}}
                    src={pic3}
                    alt='bez názvu' /></p>
          </div>
          <div className='cleaner'>
          </div>
        </div>
        <br /><br />
      </div>
      <div className='frame_col ml20'>
        <br />
        <br />
        <img src={italjet} alt='italjet skútr' />
        <br /><br />
        <img src={vespa} alt='vespa skútr' />
        <br /><br />
        <img src={gilera} alt='gilera skútr' />
        <br /><br />
        <img src={derbi} alt='derbi skútr' />
        <br /><br />
        <img src={aprilia}
             alt='aprilia skútr' />
        <br /><br />
        <img src={honda}
             alt='honda skútr' />
        <br /><br />
        <img src={peugeot}
             alt='peugeot skútr' />
        <br /><br />
        <img src={cpi}
             alt='cpi skútr' />

      </div>
      <div className='cleaner' />
    </div>
  </Layout>
);

export default IndexPage;
